import React, { useEffect, useState } from 'react';
import {
  CHANNEL_TOKEN_LENGTH,
  USER_TOKEN_LENGTH
} from '../components/utilsDesign';
import { fetchUsers } from '../components/utilsSocial';

const MessageContext = React.createContext();

const API_URL = process.env.REACT_APP_API_URL;


export const HD_CAFE_CHAT = {
  user: {
    name: 'HD Cafe Support',
    image: 'https://cdn.prod.website-files.com/643f3bd2d71a69bb42b11dfb/648d172ec0fd1cc0a59e59cc_fav.png',
  },
  avatar: {
    token: 'hd_cafe',
    image: 'https://cdn.prod.website-files.com/643f3bd2d71a69bb42b11dfb/648d172ec0fd1cc0a59e59cc_fav.png',
    name: 'HD Cafe Support'
  }
}

const MessageProvider = React.memo(({ children }) => {


  const [unauthorized, setUnauthorized] = useState(true)

  const [chatsList, setChatList] = useState()
  const [unread, setUnread] = useState()
  const [cachedChats, setCachedChats] = useState({})

  const [currentChat, setCurrentChat] = useState()

  const afterSendCallback = (newMessage) => {
    setCachedChats(prev => {
      const newChannelMessages = {}
      const prevMessages = prev[newMessage.channel] ? prev[newMessage.channel] : {}
      newChannelMessages[newMessage.channel] = [
        ...prevMessages,
        newMessage
      ]

      return ({
        ...prev,
        ...newChannelMessages
      })

    })
  }

  const sendMessage = (to, message, lastMessageId) => {
    _send(to, message, null, lastMessageId)
  }

  const _send = async (to = '', text = '', callback = () => void (0), lastMessageId) => {
    const url = `${API_URL}/api/message/send`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user: to,
          text: text
        })
      });

      if (response.ok) {
        const data = await response.json();
        // console.log('Success:', data);

        const channel = data.channel;
        fetchChatsMessages(lastMessageId ? [`${channel}/${lastMessageId}`] : [channel])
        callback && callback(data);
      } else {
        console.log('Error: ', response.status);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }

  };

  const [membersData, setMembersData] = useState()




  const fetchChatsMessages = (chatsIds = [], callback = () => void (0)) => {

    // const allChatsIds = chatIds.map(one => one._id)
    // chatIds = chatIds ? chatIds : allChatsIds;

    const updateCached = (chatData) => {
      setCachedChats(prev => {
        // console.log('chatData = ', chatData);
        return {
          ...prev,
          ...chatData
        }
      })
    }


    const chatsData = {}
    const promises = chatsIds.map(chatsIds => {
      return new Promise((resolve, reject) => {
        _getChatMessages(
          chatsIds,
          chatData => {
            chatsData[chatsIds] = chatData;
            updateCached(chatsData);
            resolve();
          },
          error => {
            console.error(`Error fetching data for user:`, error);
            resolve(); // Resolve even on error to ensure Promise.all completes
          }
        );
      });
    });

    Promise.all(promises)
      .then(() => {
        callback(chatsData);
      })
      .catch(error => {
        console.error('Error fetching all users data:', error);
      });
  };




  const getAllMembersData = (chatsList) => {
    let membersList = [];
    if (chatsList && chatsList.length) {
      chatsList.forEach(one => membersList.push(...one.members));
      membersList = [...new Set(membersList)]
    }
    // membersList = membersList.filter(one => one !== 'hd_cafe')
    return membersList;
  }


  const fetchUsersData = (membersList) => {
    if (checkIfAllMembersDataLoaded(membersList)) return;

    fetchUsers(membersList, (newMembersData) => {
      // console.log('newMembersData = ', newMembersData);

      if (Object.keys(newMembersData).length) {
        setMembersData(prev => {
          prev = prev || {}
          return {
            ...prev,
            ...newMembersData
          }
        })
      }
    },
      () => { setUnauthorized(true) }

    );
  }

  const checkIfAllMembersDataLoaded = (list = []) => {
    if (!list.length) return true;
    if (!membersData || !Object.keys(membersData).length) return false;
    return Object.keys(membersData).filter(one => !list.includes(one)).length
      || list.filter(one => !Object.keys(membersData).includes(one)).length
      ? false : true
  }

  useEffect(() => {
    const membersList = getAllMembersData(chatsList)
    if (!checkIfAllMembersDataLoaded(membersList)) {
      fetchUsersData(membersList)
    }
  }, [chatsList])

  useEffect(() => {
    // console.log('membersData = ', membersData);
  }, [membersData])



  const checkExistingChat = (to) => {
    if (!chatsList || !chatsList.length) return false
    const ex = chatsList.filter(one => one.members.includes(to));
    return ex.length ? ex[0] : false
  }


  const findChannelByMember = (to) => {
    if (!chatsList || !chatsList.length) return null
    return checkExistingChat(to) ? chatsList.filter(one => one.members.includes(to))[0]._id : null;
  }

  const startChat = async (to = '', text = '', callback = () => void (0)) => {

    const afterChatCallback = (newMessage) => {
      if (checkExistingChat(to)) {
        return findChannelByMember(to)
      } else {
        _getAllChats(allChats => {
          setChatList(allChats.reverse())
          fetchChatsMessages([newMessage.channel])
          // callback(allChats.length ? allChats[allChats.length - 1]._id : null)
          callback(newMessage.channel)
        })
      }
    }

    _send(to, text, afterChatCallback)
  };


  const _getAllChats = (callback = () => void (0), callbackError = () => void (0)) => {
    const fetchData = () => {
      return fetch(`${API_URL}/api/message/channels`)
        .then(response => {
          if (!response.ok) {
            // throw new Error('Network response was not ok');
          }
          return response.json();
        });
    };

    fetchData()
      .then(chats => {
        if (chats.status !== 404) {
          setUnauthorized(false)
          // console.log('chats = ', chats);
          callback(chats);
        } else {
          callbackError();
        }
      })
      .catch(error => {
        console.error('Error fetching user:', error);
        callbackError(error);
        setUnauthorized(true)
      });
  }


  const getChannel = (chatId, callback = () => void (0)) => {
    const chat = chatsList.filter(one => one._id === chatId);
    chat.length ? callback(chat[0]) : callback({})
  }


  const getChatMessages = (chatId, callback = () => void (0), force) => {
    return cachedChats[chatId] ? cachedChats[chatId] : false;
    // if (!force) {
    //   cachedChats[chatId] ? callback(cachedChats[chatId]) : _getChatMessages(chatId, callback)
    // } else {
    //   _getChatMessages(chatId, callback)
    // }
  }

  useEffect(() => {
    if (currentChat
      && currentChat.length === CHANNEL_TOKEN_LENGTH
      && !cachedChats[currentChat]) {
      _getChatMessages([currentChat])
    }
    if (currentChat
      && currentChat.length === USER_TOKEN_LENGTH
      // && (!membersData || !membersData[currentChat])
    ) {

      if (checkExistingChat(currentChat)) {
        setCurrentChat(checkExistingChat(currentChat)._id)
      } else {
        if (!checkIfAllMembersDataLoaded([currentChat])) {
          fetchUsersData([currentChat])
        }
      }

    }
  }, [currentChat])


  const _getChatMessages = (chatId, callback = () => void (0), callbackError = () => void (0)) => {
    const fetchData = (chatId) => {
      return fetch(`${API_URL}/api/message/messages/${chatId}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        });
    };

    fetchData(chatId)
      .then(chatData => {
        if (chatData.status !== 404) {
          setUnauthorized(false)
          callback(chatData);
        } else {
          callbackError(chatData);
        }
      })
      .catch(error => {
        console.error('Error fetching user:', error);
        callbackError(error);
        setUnauthorized(true)
      });
  };


  const _getUnreadCount = (callback = () => void (0), callbackError = () => void (0)) => {

    if (unauthorized) return callback([]);

    const fetchData = () => {
      return fetch(`${API_URL}/api/message/count`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        });
    };

    fetchData()
      .then(newMessages => {
        if (newMessages.status !== 404) {
          // console.log('newMessages = ', newMessages);
          setUnauthorized(false)
          setUnread(newMessages)
          callback(newMessages);
        } else {
          callbackError(newMessages);
        }
      })
      .catch(error => {
        console.error('Error fetching user:', error);
        callbackError(error);
        setUnauthorized(true)
      });
  };

  const getUnread = (callback = () => void (0), callbackError = () => void (0)) => {
    _getUnreadCount(callback, callbackError)
  };

  const getUnreadFor = (chatId, callback = () => void (0), force) => {
    const filterNewByChat = (chatId, newMessages = []) => {
      const newM = newMessages.filter(one => one._id === chatId);
      return newM.length ? newM[0].count : 0
    }
    if (force || !unread) {
      _getUnreadCount((newMessages) => {
        callback(filterNewByChat(chatId, newMessages))
      })
    } else {
      callback(filterNewByChat(chatId, unread))
    }
  };





  useEffect(() => {
    getUnread((un) => setUnread(un))
    // _getAllChats(data => setChatList(data))
  }, [])



  useEffect(() => {
    if (!chatsList) {
      _getAllChats(chatsList => {
        // console.log('data = ', chatsList);
        setChatList(chatsList)
        fetchChatsMessages((chatsList && chatsList.length) ? [...chatsList.map(one => one._id)] : [])
      })
    } else if (unread.length) {

      fetchChatsMessages([...unread.map(one => one._id)])
      _getAllChats(allChats => {
        setChatList(allChats.reverse())
        const membersList = getAllMembersData(allChats)
        if (!checkIfAllMembersDataLoaded(membersList)) {
          fetchUsersData(membersList)
        }
      })
    }
  }, [unread])





  // // Custom hook for managing updates
  const useChatAutoUpdates = () => {
    const [updating, setUpdating] = useState(false);


    useEffect(() => {


      const handleUpdate = () => {
        if (!unauthorized) {
          getUnread()
          setUpdating(true);
          // Simulate an update with a timeout
          setTimeout(() => {
            setUpdating(false);
          }, 2000);
        }
      };

      // Assume we have some event or condition to trigger updates
      // Here you would use WebSocket, polling, or any other mechanism
      // to listen for new messages or chat updates


      const intervalId = setInterval(handleUpdate, 5000); // Example: every 10 seconds

      return () => clearInterval(intervalId);
    }, []);

    return updating;
  };


  const updating = useChatAutoUpdates();






  const _markRead = async (chatId = '', callback = () => void (0)) => {
    const url = `${API_URL}/api/message/read-all/`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          channel: chatId
        })
      });
      if (response.ok) {
        const data = await response.json();
        // console.log('Marked read:', chatId, data);
        _getChatMessages([chatId])
        callback && callback(data);
      } else {
        console.log('Error mark read: ', chatId, response.status);
      }
    } catch (error) {
      console.error('Error mark readr:', chatId, error.message);
    }
  };



  const getMemberData = (userId) => {
    return membersData && membersData[userId] ? membersData[userId] : null
  }



  // console.log('unread = ', unauthorized);

  return (<>
    {unauthorized && children}
    {!unauthorized && <MessageContext.Provider
      value={{
        unauthorized,
        updating,
        chatsList,
        unread,
        membersData,
        cachedChats,
        currentChat,
        getChannel: getChannel,
        getChatMessages: getChatMessages,
        sendMessage: sendMessage,
        startChat: startChat,
        findChannelByMember: findChannelByMember,
        fetchChatsMessages: fetchChatsMessages,
        getUnreadFor: getUnreadFor,
        getUnread: getUnread,
        markRead: _markRead,
        setCurrentChat: setCurrentChat,
        getMemberData: getMemberData
      }}
    >
      {children}
    </MessageContext.Provider>}
  </>
  )

})

const ContextConsumer = MessageContext.Consumer;

export { ContextConsumer, MessageContext, MessageProvider };
