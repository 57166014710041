import React, { useMemo, useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Table, Checkbox, Input } from "antd";
import {
  USER_TOKEN_LENGTH, drawProfile
} from '../utilsDesign';
import { Preview } from '../UiKit/Preview';
import { Button } from '../UiKit/Button';
import { LangContext } from '../../contexts/langContext';
import { _getAdminUsersList } from '../utilsAdmin';
import { Avatar } from '../UiKit/Avatar';
import styled from 'styled-components';
import { Icon } from '../UiKit/Icon';
import { AdminMenu } from './AdminMenu';


export function AdminUsers(props) {
  const context = { ...useContext(LangContext) };
  const [fileContent, setFileContent] = useState('');
  const [data, setData] = useState([]); // Full data
  const [paginatedData, setPaginatedData] = useState([]); // Data for current page
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [designs, setDesigns] = useState({});
  const [adminUsers, setAdminUsers] = useState([]);
  const [currentFilter, setCurrentFilter] = useState();
  const [selected, setSelected] = useState([]);
  const [searched, setSearched] = useState();
  const [searchedName, setSearchedName] = useState();

  const [sortedInfo, setSortedInfo] = useState({});
  const [sortBy, setSortBy] = useState(null);

  const [verifiedFilter, setVerifiedFilter] = useState(null);
  const [localeFilter, setLocaleFilter] = useState(null);
  const [imageFilter, setImageFilter] = useState(null);
  const [designFilter, setDesignFilter] = useState(null);
  const [profileFilter, setProfileFilter] = useState(null);
  const [typeFilter, setTypeFilter] = useState(null);

  const renderProfileFilter = () => {
    return (
      <>
        {profileFilters.map(({ id, label }) => (
          <Button
            key={id}
            onClick={() => setProfileFilter(id)}
            ghost={profileFilter !== id}
          >
            {label} –
            <span style={{ opacity: 0.5 }}>
              {filterBy(adminUsers, id).length}
            </span>
            –
            <span style={{ opacity: 0.3 }}>
              {filterBy(filterBy(filterBy(adminUsers, id), currentFilter), typeFilter).length}
            </span>
          </Button>
        ))}
      </>
    );
  }

  const renderTypeFilter = () => {
    return (
      <>
        {typeFilters.map(({ id, label }) => (
          <Button
            key={id}
            onClick={() => setTypeFilter(id)}
            ghost={typeFilter !== id}
          >
            {label}
            <span style={{ opacity: 0.5 }}>
              {filterBy(adminUsers, id).length}
            </span>
            –
            <span style={{ opacity: 0.3 }}>
              {filterBy(filterBy(filterBy(adminUsers, id), currentFilter), profileFilter).length}
            </span>
          </Button>
        ))}
      </>
    );
  }

  const profileFilters = [
    { id: '13', label: '1/3' },
    { id: '14', label: '1/4' },
    { id: '24', label: '2/4' },
    { id: '25', label: '2/5' },
    { id: '35', label: '3/5' },
    { id: '36', label: '3/6' },
    { id: '46', label: '4/6' },
    { id: '41', label: '4/1' },
    { id: '51', label: '5/1' },
    { id: '52', label: '5/2' },
    { id: '62', label: '6/2' },
    { id: '63', label: '6/3' },
  ];
  const typeFilters = [
    { id: 'g', label: 'Generators' },
    { id: 'mg', label: 'Mani Gens' },
    { id: 'p', label: 'Projectors' },
    { id: 'm', label: 'Manifestors' },
    { id: 'r', label: 'Reflectors' },
  ];


  const applyFilters = (arr) => {
    return arr
      .filter(({ user }) => {
        if (verifiedFilter === null) return true;
        return verifiedFilter ? user.email_verified : !user.email_verified;
      })
      .filter(({ user }) => {
        if (!localeFilter) return true;
        return user.locale === localeFilter;
      })
      .filter(({ avatar }) => {
        if (imageFilter === null) return true;
        return imageFilter ? avatar.image : !avatar.image;
      })
      .filter(({ avatar }) => {
        if (designFilter === null) return true;
        return designFilter ? avatar.design.id : !avatar.design.id;
      })
      .filter(({ avatar }) => {
        if (!profileFilter) return true;
        return avatar.design.profile && avatar.design.profile.join('') === profileFilter;
      })
      .filter(({ avatar }) => {
        if (!typeFilter) return true;
        return avatar.design.type === typeFilter;
      });
  };

  const filteredData = useMemo(() => applyFilters(adminUsers), [adminUsers, verifiedFilter, localeFilter, imageFilter, designFilter, profileFilter, typeFilter]);

  const filterSelected = (arr) => {
    return arr.filter(({ user }) => selected.includes(user.token))
  }

  const { TextArea } = Input;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchAdminUsers = async () => {
      try {
        let data = await _getAdminUsersList();
        console.log('data = ', data);
        setAdminUsers(data); // Set the fetched data to the state variable
      } catch (error) {
        console.error('Failed to fetch admin users:', error.message);
      }
    };

    fetchAdminUsers();
  }, []); // Empty dependency array ensures this runs once on mount



  const handleTableChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
    setSortedInfo({
      order: sorter.order,
      columnKey: sorter.columnKey,
    });
  };

  const handleCityChange = (value, key) => {
    const updatedData = data.map(item => {
      if (item.key === key) {
        return { ...item, hdCity: value };
      }
      return item;
    });
    setData(updatedData);

    const updatedPaginatedData = paginatedData.map(item => {
      if (item.key === key) {
        return { ...item, hdCity: value };
      }
      return item;
    });
    setPaginatedData(updatedPaginatedData);
  };


  const sortArray = (arr, by) => {
    if (by === 'userNumber') {
      return arr.sort((a, b) => a.user.user_number - b.user.user_number);
    } else if (by === 'avatars') {
      return arr.sort((a, b) => a.user.aggr.avatars_count - b.user.aggr.avatars_count);
    } else {
      return arr;
    }
  }

  const filterBy = (arr, by) => {

    if (searched) {
      return arr.filter(({ user, avatar }) => searched === user.token);
    }

    if (searchedName) {
      return arr.filter(({ user, avatar }) => avatar.name.toLowerCase().search(searchedName.toLowerCase()) > -1);
    }

    if (!by) return arr;

    //By email verified
    if (by === 'verified') return arr.filter(({ user, avatar }) => user.email_verified);
    if (by === 'no_verified') return arr.filter(({ user, avatar }) => !user.email_verified);

    //By locale
    if (by === 'locale_en') return arr.filter(({ user, avatar }) => !user.locale || user.locale === 'en');
    if (by === 'locale_ru') return arr.filter(({ user, avatar }) => user.locale === 'ru');

    //By image
    if (by === 'image') return arr.filter(({ user, avatar }) => avatar.image);
    if (by === 'no_image') return arr.filter(({ user, avatar }) => !avatar.image);

    //By design
    if (by === 'design') return arr.filter(({ user, avatar }) => avatar.design.id);
    if (by === 'no_design') return arr.filter(({ user, avatar }) => !avatar.design.id);

    //By user city
    if (by === 'user_city') return arr.filter(({ user, avatar }) => user.city_id);
    if (by === 'no_user_city') return arr.filter(({ user, avatar }) => !user.city_id);

    //By followers
    if (by === 'followings') return arr.filter(({ user, avatar }) => user.aggr.followings_count);
    if (by === 'no_followings') return arr.filter(({ user, avatar }) => !user.aggr.followings_count);
    if (by === 'followers') return arr.filter(({ user, avatar }) => user.aggr.followers_count);
    if (by === 'no_followers') return arr.filter(({ user, avatar }) => !user.aggr.followers_count);

    //By gender
    if (by === 'gender_m') return arr.filter(({ user, avatar }) => avatar.gender === 'm');
    if (by === 'gender_f') return arr.filter(({ user, avatar }) => avatar.gender === 'f');

    //By types
    if (by === 'g') return arr.filter(({ user, avatar }) => avatar.design.type === 'G');
    if (by === 'mg') return arr.filter(({ user, avatar }) => avatar.design.type === 'MG');
    if (by === 'p') return arr.filter(({ user, avatar }) => avatar.design.type === 'P');
    if (by === 'm') return arr.filter(({ user, avatar }) => avatar.design.type === 'M');
    if (by === 'r') return arr.filter(({ user, avatar }) => avatar.design.type === 'R');

    //By profiles
    if (by === '13') return arr.filter(({ user, avatar }) => avatar.design.profile && (avatar.design.profile[0] === 1 && avatar.design.profile[1] === 3));
    if (by === '14') return arr.filter(({ user, avatar }) => avatar.design.profile && (avatar.design.profile[0] === 1 && avatar.design.profile[1] === 4));
    if (by === '24') return arr.filter(({ user, avatar }) => avatar.design.profile && (avatar.design.profile[0] === 2 && avatar.design.profile[1] === 4));
    if (by === '25') return arr.filter(({ user, avatar }) => avatar.design.profile && (avatar.design.profile[0] === 2 && avatar.design.profile[1] === 5));
    if (by === '35') return arr.filter(({ user, avatar }) => avatar.design.profile && (avatar.design.profile[0] === 3 && avatar.design.profile[1] === 5));
    if (by === '36') return arr.filter(({ user, avatar }) => avatar.design.profile && (avatar.design.profile[0] === 3 && avatar.design.profile[1] === 6));
    if (by === '46') return arr.filter(({ user, avatar }) => avatar.design.profile && (avatar.design.profile[0] === 4 && avatar.design.profile[1] === 6));
    if (by === '41') return arr.filter(({ user, avatar }) => avatar.design.profile && (avatar.design.profile[0] === 4 && avatar.design.profile[1] === 1));
    if (by === '51') return arr.filter(({ user, avatar }) => avatar.design.profile && (avatar.design.profile[0] === 5 && avatar.design.profile[1] === 1));
    if (by === '52') return arr.filter(({ user, avatar }) => avatar.design.profile && (avatar.design.profile[0] === 5 && avatar.design.profile[1] === 2));
    if (by === '62') return arr.filter(({ user, avatar }) => avatar.design.profile && (avatar.design.profile[0] === 6 && avatar.design.profile[1] === 2));
    if (by === '63') return arr.filter(({ user, avatar }) => avatar.design.profile && (avatar.design.profile[0] === 6 && avatar.design.profile[1] === 3));


  }


  const setAvasSort = (sortBy = 'avatars') => {
    setSortedInfo(prevSortedInfo => {
      const newOrder = prevSortedInfo.columnKey === sortBy && prevSortedInfo.order === 'ascend'
        ? 'descend'
        : 'ascend';
      return {
        order: newOrder,
        columnKey: sortBy,
      };
    });
  };

  useEffect(() => {
    console.log('selected = ', selected);
  }, [selected])


  const setSelectedUser = (e = { target: true }, token) => {
    // console.log(`checked = ${e.target.checked}`);
    setSelected(prev => {
      if (prev.includes(token)) {
        return prev.filter(one => one !== token)
      } else {
        return [...new Set([token, ...prev])]
      }
    })
  };

  const selectAll = () => {
    filterBy(sortedData, currentFilter).forEach(one => setSelectedUser(null, one.user.token))
  };

  const convertToHumanReadableDate = (isoDateString) => {
    const date = new Date(isoDateString);
    return date.toLocaleString(); // Converts to a human-readable format
  };


  const sortedData = useMemo(() => {
    let sorted = [...adminUsers];

    if (sortedInfo.columnKey === 'avatars') {
      sorted = sorted.sort((a, b) => {
        if (sortedInfo.order === 'ascend') {
          return a.user.aggr.avatars_count - b.user.aggr.avatars_count;
        } else if (sortedInfo.order === 'descend') {
          return b.user.aggr.avatars_count - a.user.aggr.avatars_count;
        }
        return 0; // Default case if no order is specified
      });
    }

    if (sortedInfo.columnKey === 'userNumber') {
      sorted = sorted.sort((a, b) => {
        if (sortedInfo.order === 'ascend') {
          return a.user.user_number - b.user.user_number;
        } else if (sortedInfo.order === 'descend') {
          return b.user.user_number - a.user.user_number;
        }
        return 0; // Default case if no order is specified
      });
    }

    return sorted;
  }, [adminUsers, sortedInfo]);



  const columnCheckbox = [
    {
      key: 'select',
      title: <div style={{ zIndex: 10, cursor: 'pointer' }} onClick={() => selectAll()}><Icon>check_box_outline_blank</Icon></div>,
      width: 60,
      render: (_, { user }) => <><Checkbox checked={selected.includes(user.token)} onChange={(e) => setSelectedUser(e, user.token)}></Checkbox></>,
    },
  ]

  const columnNoCheckbox = [
    {
      key: 'select',
      title: '-',
      width: 60,
      render: (_, { user }) => <><Checkbox checked={selected.includes(user.token)} onChange={(e) => setSelectedUser(e, user.token)}></Checkbox></>,
    },
  ]


  const columns = useMemo(() => [
    // {
    //   key: 'select',
    //   title: <div style={{ zIndex: 10, cursor: 'pointer' }} onClick={() => selectAll()}><Icon>check_box_outline_blank</Icon></div>,
    //   width: 60,
    //   render: (_, { user }) => <><Checkbox checked={selected.includes(user.token)} onChange={(e) => setSelectedUser(e, user.token)}></Checkbox></>,
    // },
    {
      key: 'userNumber',
      title: '#',
      sorter: (a, b) => a.user.user_number - b.user.user_number,
      sortOrder: sortedInfo?.columnKey === 'userNumber' && sortedInfo?.order, // Set sort order dynamically
      onHeaderCell: () => ({
        onClick: () => setAvasSort('') // Trigger sorting when header is clicked
      }),
      width: 60,
      render: (_, { user }) => user.user_number,
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      render: (_, { user }) => <>{user.email.split('@')[0]}@<wbr />{user.email.split('@')[1]}</>,
      width: 160,
      ellipsis: true,
    },
    {
      key: 'emailVerified',
      width: 60,
      title: <Icon>mark_email_read</Icon>,
      render: (_, { user }) => user.email_verified ? <Icon>check</Icon> : '-',
    },
    {
      key: 'created',
      title: 'Created',
      render: (_, { user }) => convertToHumanReadableDate(user.created),
    },

    {
      key: 'userTitle',
      title: 'User Name',
      dataIndex: 'title',
      render: (_, { user }) => user.name,
    },
    {
      key: 'userCity',
      title: 'City',
      render: (_, { user }) => user.city_id ? <><a href={`https://hd.cafe/api/geo/city/${user.city_id}`} target="_blank">{user.city_id}</a></> : '-',
    },
    {
      key: 'followers',
      title: 'F-ers',
      render: (_, { user }) => user.aggr.followers_count ? user.aggr.followers_count : 0,
    },
    {
      key: 'followings',
      title: 'F-ing',
      render: (_, { user }) => user.aggr.followings_count ? user.aggr.followings_count : 0,
    },
    {
      title: 'Avatars',
      dataIndex: 'avatars',
      key: 'avatars',
      sorter: (a, b) => a.user.aggr.avatars_count - b.user.aggr.avatars_count,
      sortOrder: sortedInfo?.columnKey === 'avatars' && sortedInfo?.order, // Set sort order dynamically
      onHeaderCell: () => ({
        onClick: () => setAvasSort('avatars') // Trigger sorting when header is clicked
      }),
      render: (_, { user }) => user.aggr.avatars_count ? user.aggr.avatars_count : 0,
    },

    {
      key: 'pentas',
      title: 'Pnts',
      render: (_, { user }) => user.aggr.pentas_count ? user.aggr.pentas_count : 0,
    },

    {
      key: 'locale',
      title: 'Ln',
      render: (_, { user }) => user.locale === 'ru' ? 'Ru' : 'En',
    },




    {
      key: 'ava',
      title: 'Avatar',
      render: (_, { avatar }) => (
        <>
          <Avatar
            avatar={avatar}
            style={{ fontSize: '1em' }}
            size='sm'
          />
        </>
      ),
    },
    {
      key: 'avatarTitle',
      title: 'Avatar Name',
      render: (_, { avatar }) => avatar.name,
    },
    {
      key: 'avatarDob',
      title: 'DOB',
      render: (_, { avatar }) => avatar.dob,
    },
    {
      key: 'avatarCity',
      title: 'City OB',
      render: (_, { avatar }) => avatar.aggr.city ? avatar.aggr.city.name : <><a href={`https://hd.cafe/api/geo/city/${avatar.city_id}`} target="_blank">{avatar.city_id}</a></>,
    },
    {
      key: 'type',
      title: 'Type',
      render: (_, { avatar }) => (
        <>
          {avatar.design.type}
        </>
      ),
    },
    {
      key: 'profile',
      title: 'Profile',
      render: (_, { avatar }) => (
        <>
          {/* {avatar.design.profile.length === 2 && <>{avatar.design.profile[0]} / {avatar.design.profile[1]}</>} */}
          {drawProfile(avatar.design.profile)}
        </>
      ),
    },

    // {
    //   key: 'hd_city',
    //   title: 'Found City',
    //   dataIndex: 'hdCity',
    //   render: (_, record) => (
    //     <>
    //       {record.cities.length > 1 ? (
    //         <Select
    //           value={record.hdCity}
    //           onChange={(value) => handleCityChange(value, record.key)}
    //           style={{ width: 400, backgroundColor: '#f0f0f0' }}
    //           options={record.cities.map((city, index) => ({
    //             value: city,
    //             label: city,
    //           }))}
    //         />
    //       ) : record.cities[0] ? (
    //         <>{record.cities[0]}</>
    //       ) : (
    //         <b style={{ color: 'red' }}>Not found</b>
    //       )}
    //     </>
    //   ),
    // },
    {
      key: 'design',
      title: 'Design',
      dataIndex: 'design',
      render: (_, { user, avatar }) => (
        <div onClick={() => window.open(`/u/${user.token}`, '_blank')} >
          <Preview
            arrowsB={true}
            arrowsP={true}
            width={60}
            height={60}
            aura={true}
            design={avatar.design}
          />
        </div>
      ),
    },
    // {
    //   key: 'import',
    //   title: 'Import',
    //   dataIndex: '',
    //   render: (_, { types }) => (
    //     <Button
    //       disabled={!_.design.type}
    //       onClick={() => context.x.createAvatar({
    //         name: !_.design.title,
    //         dob: `${_.datar}T${_.timer}:00`,
    //         city_id: _.hdCityId,
    //       })
    //       }
    //     >
    //       Import
    //     </Button >
    //   ),
    // },
  ], [adminUsers, selected]);


  const findByName = (val) => {
    if (val) {
      setSearchedName(val)
    } else {
      setSearchedName()
    }
  }

  const findByToken = (val) => {
    if (val.length === USER_TOKEN_LENGTH) {
      setSearched(val)
    } else {
      setSearched()
    }
  }




  return (<>
    {Boolean(adminUsers.length) && <AdminMenu active="users" />}
    <div className="b" style={{
      position: 'relative',
      minHeight: '100vh',
      padding: '2em 2em 12em 2em',
      background: '#efefef',
    }}>

      {Boolean(adminUsers.length) && <>

        <div className="_fv _fgap2">

          <div className="_fv _fgap2">

            <div className="_f _fgap2 _fwrap">
              <Button onClick={() => {
                setCurrentFilter()
                setTypeFilter()
                setProfileFilter()
              }} ghost={currentFilter ? true : false}>All {adminUsers.length}</Button>
              <div className="_f _fgap02 _fm">
                Verified:
                <Button onClick={() => setCurrentFilter('verified')} ghost={currentFilter === 'verified' ? false : true}>{filterBy(adminUsers, 'verified').length}</Button>
                <Button onClick={() => setCurrentFilter('no_verified')} ghost={currentFilter === 'no_verified' ? false : true} red={currentFilter !== 'no_verified' ? false : true}>{filterBy(adminUsers, 'no_verified').length}</Button>
              </div>

              <div className="_f _fgap02 _fm">
                <Button onClick={() => setCurrentFilter('locale_en')} ghost={currentFilter === 'locale_en' ? false : true}>En {filterBy(adminUsers, 'locale_en').length}</Button>
                <Button onClick={() => setCurrentFilter('locale_ru')} ghost={currentFilter === 'locale_ru' ? false : true}>Ru {filterBy(adminUsers, 'locale_ru').length}</Button>
              </div>

              <div className="_f _fgap02 _fm">
                Image:
                <Button onClick={() => setCurrentFilter('image')} ghost={currentFilter === 'image' ? false : true}>{filterBy(adminUsers, 'image').length}</Button>
                <Button onClick={() => setCurrentFilter('no_image')} ghost={currentFilter === 'no_image' ? false : true} red={currentFilter !== 'no_image' ? false : true}>{filterBy(adminUsers, 'no_image').length}</Button>
              </div>

              <div className="_f _fgap02 _fm">
                Design:
                <Button onClick={() => setCurrentFilter('design')} ghost={currentFilter === 'design' ? false : true}>{filterBy(adminUsers, 'design').length}</Button>
                <Button onClick={() => setCurrentFilter('no_design')} ghost={currentFilter === 'no_design' ? false : true} red={currentFilter !== 'no_design' ? false : true}>{filterBy(adminUsers, 'no_design').length}</Button>
              </div>

              <div className="_f _fgap02 _fm">
                City:
                <Button onClick={() => setCurrentFilter('user_city')} ghost={currentFilter === 'user_city' ? false : true}>{filterBy(adminUsers, 'user_city').length}</Button>
                <Button onClick={() => setCurrentFilter('no_user_city')} ghost={currentFilter === 'no_user_city' ? false : true} red={currentFilter !== 'no_user_city' ? false : true}>{filterBy(adminUsers, 'no_user_city').length}</Button>
              </div>

              <div className="_f _fgap02 _fm">
                Followings:
                <Button onClick={() => setCurrentFilter('followings')} ghost={currentFilter === 'followings' ? false : true}>{filterBy(adminUsers, 'followings').length}</Button>
                <Button onClick={() => setCurrentFilter('no_followings')} ghost={currentFilter === 'no_followings' ? false : true} red={currentFilter !== 'no_followings' ? false : true}>{filterBy(adminUsers, 'no_followings').length}</Button>
              </div>

              <div className="_f _fgap02 _fm">
                Followers:
                <Button onClick={() => setCurrentFilter('followers')} ghost={currentFilter === 'followers' ? false : true}>{filterBy(adminUsers, 'followers').length}</Button>
                <Button onClick={() => setCurrentFilter('no_followers')} ghost={currentFilter === 'no_followers' ? false : true} red={currentFilter !== 'no_followers' ? false : true}>{filterBy(adminUsers, 'no_followers').length}</Button>
              </div>

              <div className="_f _fgap02 _fm">
                <Button onClick={() => setCurrentFilter('gender_m')} ghost={currentFilter === 'gender_m' ? false : true}>M {filterBy(adminUsers, 'gender_m').length}</Button>
                <Button onClick={() => setCurrentFilter('gender_f')} ghost={currentFilter === 'gender_f' ? false : true}>F {filterBy(adminUsers, 'gender_f').length}</Button>
              </div>

              <div className="_f">

                <Input placeholder="Find by name" allowClear onChange={(e) => findByName(e.target.value)} />
                <Input placeholder="Find by token" allowClear onChange={(e) => findByToken(e.target.value)} />
              </div>

            </div>

            <div className="_f _fwrap">
              {renderTypeFilter()}
              <div />
              <div />
              <div />
              {renderProfileFilter()}




            </div>

            <div className="_f _f100 _fr _fwrap">
              {/* <Button onClick={selectAll}>Select all</Button> */}
              <Button ghost onClick={() => setAvasSort('avatars')}>Sort by avatars</Button>
              <Button ghost onClick={() => setAvasSort('userNumber')}>Sort by number</Button>
              <Button red ghost onClick={() => setSelected([])}>Select none</Button>
            </div>



            <Table
              columns={[...columnCheckbox, ...columns]}
              dataSource={sortArray(
                filterBy(
                  filterBy(
                    filterBy(adminUsers, typeFilter),
                    profileFilter),
                  currentFilter),
                sortBy
              )}
              loading={loading}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: data.length,
              }}
              onChange={handleTableChange}
            />
          </div>


          <div className="_fv">
            <h2>Selected</h2>
            <Selected style={{ transfrom: selected.length ? `translateY(12em)` : `translateY(0em)` }}>
              <Table
                columns={[...columnNoCheckbox, ...columns]}
                pagination={false}
                dataSource={filterSelected(adminUsers)}
              />
            </Selected>

            <TextArea
              value={filterSelected(adminUsers).reduce((prev, { user }) => prev + `https://hd.cafe/u/${user.token}\n`, '')} // Initial value is passed here
              readOnly
              autoSize
              rows={filterSelected(adminUsers).length}
              cols={100}
              placeholder="Selected users"
            />

            <TextArea
              value={filterSelected(adminUsers).map(({ user }) => user.token).toString()}
              readOnly
              autoSize
              rows={filterSelected(adminUsers).length}
              cols={100}
              placeholder="Selected users"
            />

          </div>

        </div>

      </>}

      {!Boolean(adminUsers.length) && <>
        <div className="_fv _fcc _f100">
          <Button ghost onClick={() => navigate('/')}>Go to mainpage</Button>
        </div>
      </>}

    </div>



  </>
  );
}



const Selected = styled.div`
  /* position: sticky; */
  /* bottom: 0; */
  width: 100%;
  z-index: 1000;
  background-color: #d3d3d3;
  box-shadow: 0 0 20px 3px #00000034;
  /* max-height: 8em; */
  /* overflow-y: auto; */
`




