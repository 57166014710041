import { authorities, authoritiesDesc } from '../static/centers_dictionary';
import { channels_dictionary } from '../static/channels_dictionary';
import { profiles_dictionary, profilesValid } from '../static/profiles_dictionary';
import { typeColors, types_dict } from '../static/types_dictionary';

import dayjs from 'dayjs';
import utc from 'dayjs-plugin-utc';
import { crosses_dictionary, crosses_types } from '../static/crosses_dictionary';
import { gates_dictionary } from '../static/gates_dictionary';

import 'dayjs/locale/ru';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { default_design } from '../static/default_design';

dayjs.extend(localizedFormat)
dayjs.extend(utc);

const API_URL = process.env.REACT_APP_API_URL;
const SERVICE_DESIGN = process.env.REACT_APP_SERVICE_DESIGN;

export const USER_TOKEN_LENGTH = 16;
export const AVATAR_TOKEN_LENGTH = 18;
export const PENTA_TOKEN_LENGTH = 20;
export const CHANNEL_TOKEN_LENGTH = 24;
export const GROUP_TOKEN_LENGTH = 13;


const ln = 'en'


export const defaultDate = '1990-01-01';
export const defaultTime = '12:00';
export const defaultOffset = 0;
export const defaultDob = `${defaultDate}T${defaultTime}:00`;



export const countByType = (handleType, all = [], what = 'type') => {
  let newA = all;
  if (what === 'type') newA = newA.filter(one => one['design'] && one['design']['type'] === handleType)
  if (what === 'line') newA = newA.filter(one => one['design'] && one['design']['profile'].includes(handleType))
  if (what === 'profile') newA = newA.filter(one => profileToString(one['design'] && one['design']['profile']) === handleType)
  return newA.length
}

// export const countByEnv = (handleType, all = [], what = 'environment') => {
//   let newA = all;
//   newA = newA.filter(one => getDesignNodeColor(one['design'], 'index') === handleType)
//   return newA.length
// }

export const countByChannel = (gatesArray, all = []) => {
  let newA = all;
  newA = newA.filter(one => one['design'] && checkIfHaveChannel(one['design'], gatesArray))
  return newA.length
}

export const countByAuth = (auth = [], all = []) => {
  let newA = all;
  newA = newA.filter(one => one['design'] && auth.includes(one['design']['auth']))
  return newA.length
}



export const profileToString = (profileArr) => {
  return profileArr ? `${profileArr[0]}/${profileArr[1]}` : null
}


export const checkIfHaveChannel = (design, gatesArray) => {
  if (!design || !design.gates) return []
  // if (!design['gates'] || design['gates'].length) return []
  return design.gates.filter(one => one.gate === gatesArray[0]).length && design.gates.filter(one => one.gate === gatesArray[1]).length
}




// BRAIN ← ^

//DESIGN SUN COLOR
export const getDesignSunColor = (design, upDown, full) => {
  if (!design || !design.gates) return
  let num = design.gates.filter(one => one.planet === 'Sun' && one.label === 'des');
  if (full && num[0]) {
    return num[0]
  }
  if (num[0]) { num = num[0]['color'] } else { return }
  if (upDown) {
    return num < 4 ? 'down' : 'up'
  }
  return num
}

//DESIGN SUN TONE
export const getDesignSunTone = (design, rightLeft, full) => {
  // console.log('design = ', design);
  if (!design || !design.gates) return
  let num = design.gates.filter(one => one.planet === 'Sun' && one.label === 'des');
  if (full && num[0]) {
    return num[0]
  }
  if (num[0]) { num = num[0]['tone'] } else { return }
  if (rightLeft) {
    return num < 4 ? 'left' : 'right'
  }
  // return num.toString()
  return num
}

//DESIGN SUN BASE
export const getDesignSunBase = (design) => {
  if (!design) return
  let num = design.gates.filter(one => one.planet === 'Sun' && one.label === 'des');
  if (num[0]) { num = num[0]['base'] } else { return }
  return num
}

const getTransference2 = (num) => {
  const trans = {
    '1': 4,
    '2': 5,
    '3': 6,
    '4': 1,
    '5': 2,
    '6': 3
  }
  return trans[num]
}

export const getTransference = {
  "1": 4,
  "2": 5,
  "3": 6,
  "4": 1,
  "5": 2,
  "6": 3
}


// ← ↓  ENVIRONMENT

// DESIGN NODE COLOR
export const getDesignNodeColor = (design, upDown, full) => {
  if (!design || !design.gates) return
  let num = design.gates.filter(one => one.planet === 'South_Node' && one.label === 'des');
  if (full && num[0]) {
    return num[0]
  }
  if (num[0]) { num = num[0]['color'] } else { return }
  if (upDown) {
    return num < 4 ? 'down' : 'up'
  }
  return num
}

//DESIGN NODE TONE
export const getDesignNodeTone = (design, rightLeft, full) => {
  if (!design || !design.gates) return
  let num = design.gates.filter(one => one.planet === 'South_Node' && one.label === 'des');
  if (full && num[0]) {
    return num[0]
  }
  if (num[0]) { num = num[0]['tone'] } else { return }
  if (rightLeft) {
    return num < 4 ? 'left' : 'right'
  }
  return num
}


//DESIGN NODE BASE
export const getDesignNodeBase = (design) => {
  if (!design || !design.gates) return
  let num = design.gates.filter(one => one.planet === 'South_Node' && one.label === 'des');
  if (num[0]) { num = num[0]['base'] } else { return }
  // return num.toString()
  return num
}






// →  ^ MOTIVATION

//PERSONALITY SUN COLOR
export const getPersSunColor = (design, upDown, full) => {
  if (!design || !design.gates) return
  let num = design.gates.filter(one => one.planet === 'Sun' && one.label === 'prs');
  if (full && num[0]) {
    return num[0]
  }
  if (num[0]) { num = num[0]['color'] } else { return }
  if (upDown) {
    return num < 4 ? 'down' : 'up'
  }
  return num
}

//PERSONALITY SUN TONE
export const getPersSunTone = (design, rightLeft, full) => {
  if (!design || !design.gates) return
  let num = design.gates.filter(one => one.planet === 'Sun' && one.label === 'prs');
  if (full && num[0]) {
    return num[0]
  }
  if (num[0]) { num = num[0]['tone'] } else { return }
  if (rightLeft) {
    return num < 4 ? 'left' : 'right'
  }
  return num
}

//PERSONALITY SUN BASE
export const getPersSunBase = (design) => {
  if (!design || !design.gates) return
  let num = design.gates.filter(one => one.planet === 'Sun' && one.label === 'prs');
  if (num[0]) { num = num[0]['base'] } else { return }
  // return num.toString()
  return num
}



// → ↓ PERSPECTIVE

//PERSONALITY NODE COLOR
export const getPersNodeColor = (design, upDown, full) => {
  if (!design || !design.gates) return
  let num = design.gates.filter(one => one.planet === 'South_Node' && one.label === 'prs');
  if (full && num[0]) {
    return num[0]
  }
  if (num[0]) { num = num[0]['color'] } else { return }
  if (upDown) {
    return num < 4 ? 'down' : 'up'
  }
  return num
}

//PERSONALITY NODE TONE
export const getPersNodeTone = (design, rightLeft, full) => {
  if (!design || !design.gates) return
  let num = design.gates.filter(one => one.planet === 'South_Node' && one.label === 'prs');
  if (full && num[0]) {
    return num[0]
  }
  if (num[0]) { num = num[0]['tone'] } else { return }
  if (rightLeft) {
    return num < 4 ? 'left' : 'right'
  }
  return num
}

//PERSONALITY NODE BASE
export const getPersNodeBase = (design) => {
  if (!design || !design.gates) return
  let num = design.gates.filter(one => one.planet === 'South_Node' && one.label === 'prs');
  if (num[0]) { num = num[0]['base'] } else { return }
  // return num.toString()
  return num
}





export const getArrow = {
  'diet': 'left_up',
  'env': 'left_down',
  'motivation': 'right_up',
  'perspective': 'right_down'
}

export const getColor = {
  'diet': getDesignSunColor,
  'env': getDesignNodeColor,
  'motivation': getPersSunColor,
  'perspective': getPersNodeColor
}
export const getTone = {
  'diet': getDesignSunTone,
  'env': getDesignNodeTone,
  'motivation': getPersSunTone,
  'perspective': getPersNodeTone
}
export const getBase = {
  'diet': getDesignSunBase,
  'env': getDesignNodeBase,
  'motivation': getPersSunBase,
  'perspective': getPersNodeBase
}






export const getMars = (design, label = 'des') => {
  if (!design) return
  let num = design.gates.filter(one => one.planet === 'Mars' && one.label === label);
  if (num[0]) { num = num[0]['line'] } else { return }
  // return num.toString()
  return num
}


export const getMoon = (design, label = 'des') => {
  if (!design) return
  let num = design.gates.filter(one => one.planet === 'Moon' && one.label === label);
  if (num[0]) { num = num[0]['line'] } else { return }
  // return num.toString()
  return num
}



export const getAvatarClass = (design) => {
  if (!design || !design.type) return ''
  const classes = {
    "M": 't_icon--ava--m',
    "G": 't_icon--ava--g',
    "MG": 't_icon--ava--g',
    "P": 't_icon--ava--p',
    "R": 't_icon--ava--r',
  }
  return classes[design.type]

}





export const _getPenta = async (pentaToken = '', callback = () => void (0)) => {
  const url = `${API_URL}/api/public/penta/${pentaToken}`;
  try {
    const response = await fetch(url, {
      method: 'GET',
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    const data = await response.json();
    data.members = parseDesignInPersonas(data.members)
    console.log('Success:', data);
    callback(data)
  } catch (error) {
    console.error('Error:', error.message);
  }
};





export const _getUser = (userId, callback = () => void (0), callbackError = () => void (0)) => {
  const fetchData = (what) => {
    return fetch(`${API_URL}/api/${what}`)
      .then(response => {
        if (!response.ok) {
          // throw new Error('Network response was not ok');
        }
        return response.json();
      });
  };

  fetchData(`user/${userId}`)
    .then(userData => {
      if (userData.status !== 404) {
        userData.avatar = parseAvatar(userData.avatar)
        callback(userData);
      } else {
        callbackError(userData);
      }
    })
    .catch(error => {
      console.error('Error fetching user:', error);
      callbackError(error);
    });
};


export const _getPublicUser = (userId, callback = () => void (0), callbackError = () => void (0)) => {
  const fetchData = (what) => {
    return fetch(`${API_URL}/api/public/${what}`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
      });
  };

  fetchData(`user/${userId}`)
    .then(userData => {
      if (userData.status !== 404) {
        callback(userData);
      } else {
        callbackError(userData);
      }
    })
    .catch(error => {
      console.log('Error fetching user:', error);
      callbackError(error);
    });
};







export const defineAuthority = (centers) => {
  if (centers.includes('SP')) return 'SP';
  if (!centers.includes('SP') && centers.includes('SL')) return 'SL';
  if (!centers.includes('SP') && !centers.includes('SL') && centers.includes('SN')) return 'SN';
  if (!centers.includes('SP') && !centers.includes('SL') && !centers.includes('SN') && centers.includes('HT')) return 'HT';
  if (!centers.includes('SP') && !centers.includes('SL') && !centers.includes('SN') && !centers.includes('HT') && centers.includes('GC')) return 'GC';
  return false;
}

export const getDesignCenters = (gates1 = [], gates2 = []) => {
  let ret = [];
  let compositeGates = [...gates1, ...gates2];
  let channels = gatherUserChannels(compositeGates)
  channels.forEach(one => {
    ret.push(...one['centers'])
  })
  ret = [...new Set(ret)]
  if (!gates1.length && !gates2.length) {
    ret = []
  }
  return ret;
}




export const findGateInUser = (gates, gateNum, label) => {
  if (!gates || !gates.length) return [];
  if (typeof gateNum === 'string') gateNum = parseInt(gateNum);

  let result = gates.filter(one => one && one['gate'] === gateNum);

  if (label) {
    result = result.filter(one => one['label'] === label);
  }

  // !!result.length && console.log('result = ', result);
  return result.length ? result : [];
}


export const findGateByPlanet = (gates, planet = 'Sun', label = 'des') => {
  if (!gates || !gates.length) return [];

  let result = gates.filter(one => one && one['planet'] === planet);

  result = result.filter(one => one['label'] === label);

  return result.length ? result[0] : {};
}


export const gatherUserChannels = (gates, returnChannels = true, onlyOpenGates) => {

  // let channels = []
  // gates.forEach(oneGate => {
  //   let channelsWithOneGate = channels_dictionary.filter(oneChannel => {
  //     if (
  //       oneChannel['gates'].includes(oneGate['gate']) &&
  //       findGateInUser(gates, gates_dictionary[oneGate['gate'].toString()]['corresponding_gate'])
  //     ) {
  //       return true
  //     }
  //   })
  //   channels = channelsWithOneGate.length ? [...channels, ...channelsWithOneGate] : channels
  // })

  let channels = [];
  let userGates = [];

  function removeDuplicatesByGate(entries) {
    const uniqueEntries = entries.reduce((acc, entry) => {
      const gate = entry.gate;
      if (!acc.some((item) => item.gate === gate)) {
        acc.push(entry);
      }
      return acc;
    }, []);
    return uniqueEntries;
  }




  channels_dictionary.forEach(oneChannel => {
    //TODO: разобраться с 10-34 10-20...
    if (
      findGateInUser(gates, oneChannel['gates'][0]).length &&
      findGateInUser(gates, oneChannel['gates'][1]).length
    ) {
      channels = [oneChannel, ...channels];
    } else {
      if (findGateInUser(gates, oneChannel['gates'][0])) {
        userGates = [...findGateInUser(gates, oneChannel['gates'][0]), ...userGates]
      }
      if (findGateInUser(gates, oneChannel['gates'][1])) {
        userGates = [...findGateInUser(gates, oneChannel['gates'][1]), ...userGates]
      }

    }
  })

  // let a = JSON.stringify(userGates)

  if (onlyOpenGates) {
    return removeDuplicatesByGate(userGates)
  }


  return returnChannels
    ? Array.from(new Set(channels.map(JSON.stringify))).map(JSON.parse)
    : sortGatesByLabel(removeDuplicatesByGate(userGates))
}





function sortGatesByLabel(entries) {
  function getLabelPriority(label) {
    // Assigning a priority value to each label
    // "prs" has a higher priority (lower value) than "des"
    switch (label) {
      case "prs":
        return 0;
      case "des":
        return 1;
      default:
        // If the label is not "prs" or "des", we consider it as "des"
        return 1;
    }
  }

  // Using the compare function with the label priority for sorting
  entries.sort((entry1, entry2) => {
    const label1 = entry1.label;
    const label2 = entry2.label;
    const priority1 = getLabelPriority(label1);
    const priority2 = getLabelPriority(label2);

    if (priority1 !== priority2) {
      return priority1 - priority2;
    }

    // If both labels have the same priority, sort them lexicographically
    return label1.localeCompare(label2);
  })

  return entries;
}




export const drawType = (typeInd, show, authority = null) => {
  if (!typeInd) return
  let ret = ''
  if (show === 'bg') ret = typeColors[typeInd]['bg'];
  if (show === 'border') ret = typeColors[typeInd]['border'];
  if (show === 'class') ret = typeInd ? typeInd.toLowerCase() : '';
  if (show === 'short') ret = typeInd;
  if (!show || show === 'normal') ret = types_dict[typeInd]['title'];
  if (show === 'full') ret = types_dict[typeInd]['titleFull'];
  if (show === 'plural') ret = types_dict[typeInd]['titlePlural'];
  if (show === 'compact') ret = types_dict[typeInd]['titleCompact'];
  if (show === 'compact_plural') ret = types_dict[typeInd]['titleCompactPlural'];
  if (show === 'auth' && authority) ret = drawAuthority(authority, 'auth', typeInd) + ' ' + ret;
  if (show === 'authFull' && authority) ret = drawAuthority(authority, show) + ' ' + ret;
  return ret;
}

export const drawAuthority = (authority, show = 'auth', typeInd) => {
  let ret = '';
  // if (!authorities['en'][authority]) authority = 'RFL'
  if (typeInd === 'R') authority = 'RFL'
  if (authority && show === 'auth') ret = authorities['en'][authority] ? authorities['en'][authority] : ret;
  if (authority && show === 'authFull') ret = authoritiesDesc['en'][authority] ? authoritiesDesc['en'][authority] : ret;
  return ret;
}




export const drawProfile = (profileArr = [], draw = {
  numbers: true,
  title: false,
  description: false,
  description: false,
}) => {
  if (profileArr.length !== 2) return '';
  let ret = ''
  if (draw['numbers']) ret = `${profileArr[0]}/${profileArr[1]}`;
  if (draw['title']) ret += ' ' + `${profiles_dictionary[`${profileArr[0]}/${profileArr[1]}`]['title']}`;
  // if (draw['description']) ret = `${profiles_dictionary[`${profileArr[0]}/${profileArr[1]}`]['title']}`;
  if (draw['description']) ret = `${profiles_dictionary[`${profileArr[0]}/${profileArr[1]}`]['description']}`;
  return ret;
}




export const drawDate = (date, type = 'May 26') => {
  if (!date) return ''
  let ret = '';
  if (type === 'May 26') {
    let d = parseInt(date.split('-')[1]) - 1;
    if (!months[d]) return
    ret = `${months[d]['short']} ${(date.split('-')[2]).toString().padStart(2, '0')}`;
  }
  if (type === 'year') {
    ret = `${parseInt(date.split('-')[0])}`;
  }
  if (type === 'date') {
    ret = `${date[0]}-${date[1]}-${date[2]}`;
  }
  if (type === 'time') {
    let d = date.split(' ')[1]
    ret = d;
    // ret = `${date[3].toString().padStart(2, '0')}:${date[4].toString().padStart(2, '0')}`;
  }
  return ret
}


const dob_default = [1990, 1, 1, 12, 0, 1]

export const drawDateFromArray = (dob = []) => {
  if (!dob.length) dob = dob_default;
  // let dat = `${dob['0']}-${dob['1'].toString().padStart(2, '0')}-${dob['2'].toString().padStart(2, '0')} ${dob['3'].toString().padStart(2, '0')}:${dob['4'].toString().padStart(2, '0')}`
  dob[0] = typeof dob[0] !== 'undefined' ? dob[0] : 1990; //year
  dob[1] = typeof dob[1] !== 'undefined' ? dob[1] : 1; //month
  dob[2] = typeof dob[2] !== 'undefined' ? dob[2] : 1; //day
  dob[3] = typeof dob[3] !== 'undefined' ? dob[3] : 1; //hour
  dob[4] = typeof dob[4] !== 'undefined' ? dob[4] : 1; //minute
  dob[5] = typeof dob[5] !== 'undefined' ? dob[5] : 1; //second
  let dat = `${dob['0']}-${dob['1'].toString().padStart(2, '0')}-${dob['2'].toString().padStart(2, '0')}`
  return dat
}


export const drawTimeFromArray = (dob = []) => {
  if (!dob.length) dob = dob_default;
  let dat = `${dob['3'].toString().padStart(2, '0')}:${dob['4'].toString().padStart(2, '0')}`
  return dat
}





export const hours = [
  ['00', '0', '12'],
  ['01', '1', '1'],
  ['02', '2', '2'],
  ['03', '3', '3'],
  ['04', '4', '4'],
  ['05', '5', '5'],
  ['06', '6', '6'],
  ['07', '7', '7'],
  ['08', '8', '8'],
  ['09', '9', '9'],
  ['10', '10', '10'],
  ['11', '11', '11'],
  ['12', '12', '00'],
  ['13', '13', '01'],
  ['14', '14', '02'],
  ['15', '15', '03'],
  ['16', '16', '04'],
  ['17', '17', '05'],
  ['18', '18', '06'],
  ['19', '19', '07'],
  ['20', '20', '08'],
  ['21', '21', '09'],
  ['22', '22', '10'],
  ['23', '23', '11']
]





export const months = [
  {
    short: 'Jan',
    long: 'January',
    num: 1
  },
  {
    short: 'Feb',
    long: 'February',
    num: 2
  },
  {
    short: 'Mar',
    long: 'March',
    num: 3
  },
  {
    short: 'Apr',
    long: 'April',
    num: 4
  },
  {
    short: 'May',
    long: 'May',
    num: 5
  },
  {
    short: 'Jun',
    long: 'June',
    num: 6
  },
  {
    short: 'Jul',
    long: 'July',
    num: 7
  },
  {
    short: 'Aug',
    long: 'August',
    num: 8
  },
  {
    short: 'Sep',
    long: 'September',
    num: 9
  },
  {
    short: 'Oct',
    long: 'October',
    num: 10
  },
  {
    short: 'Nov',
    long: 'November',
    num: 11
  },
  {
    short: 'Dec',
    long: 'December',
    num: 12
  },
]
export const monthsRu = [
  {
    short: 'Янв',
    long: 'Январь',
    num: 1
  },
  {
    short: 'Фев',
    long: 'Февраль',
    num: 2
  },
  {
    short: 'Мар',
    long: 'Март',
    num: 3
  },
  {
    short: 'Апр',
    long: 'Апрель',
    num: 4
  },
  {
    short: 'Май',
    long: 'Май',
    num: 5
  },
  {
    short: 'Июн',
    long: 'Июнь',
    num: 6
  },
  {
    short: 'Июл',
    long: 'Июль',
    num: 7
  },
  {
    short: 'Авг',
    long: 'Август',
    num: 8
  },
  {
    short: 'Сен',
    long: 'Сентябрь',
    num: 9
  },
  {
    short: 'Окт',
    long: 'Октябрь',
    num: 10
  },
  {
    short: 'Ноя',
    long: 'Ноябрь',
    num: 11
  },
  {
    short: 'Дек',
    long: 'Декабрь',
    num: 12
  },
]


export const getMonth = (index, lang = 'en', format = 'long') => {
  const m = {
    en: months,
    ru: monthsRu,
  }
  return m[lang][index][format]
}



export const _getNewDesign = async (dob = '', tz, callback) => {

  // console.log('dob = ', dob);
  try {
    // dob = dob.replace('T', '')
    const designResponse = await fetch(`${SERVICE_DESIGN}/api/date?date=${dob}&zone=${tz}`);
    let newDesign = await designResponse.json();
    newDesign = parseNewDesign(newDesign)
    callback(newDesign)
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const _getNewDesignRange = async (dob1 = '', dob2 = '', callback) => {

  // console.log('dob = ', dob);
  try {
    dob1 = dob1.replace('.000Z', '')
    dob2 = dob2.replace('.000Z', '')
    // const designResponse = await fetch(`${SERVICE_DESIGN}/api/dates?from=${dob1}&to=${dob2}&zone=${tz}`);
    const designResponse = await fetch(`${SERVICE_DESIGN}/api/dates?from=${dob1}&to=${dob2}&zone=UTC`);
    let range = await designResponse.json();
    range = parseRangeDesign(range)
    callback(range)
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

const parseNewDesign2 = (design = {}, date = '') => {

  const parsePlanet = (planet = {}) => {
    return {
      "planet": planet.pl,
      "lon": planet.lon,
      "gate": planet.v[0],
      "line": planet.v[1],
      "color": planet.v[2],
      "tone": planet.v[3],
      "base": planet.v[4],
      "perGate": planet.p[0],
      "perLine": planet.p[1],
      "perColor": planet.p[2],
      "perTone": planet.p[3],
      "perBase": planet.p[4]
    }
  }

  const d = {
    "meta": {
      "personality": design.pd,
      "design": design.dd
    },
    "personality": design.p.map(parsePlanet),
    "design": design.d.map(parsePlanet)
  }
  return d
}

const parseRangeDesign = (range = {}) => {
  const ret = {}
  Object.keys(range).forEach(d => {
    const dWithoutT = d.includes(':') && d.split(':').length === 2 ? `${d}:00` : d;
    ret[dWithoutT] = parseNewDesign2(range[d], d)
  })
  return ret
}

export const _getNewDesignHD = async (dob = '', tz, callback) => {

  // console.log('dob = ', dob);
  try {
    // dob = dob.replace('T', '')
    const designResponse = await fetch(`https://hd.cafe/design/api/date?date=${dob}&zone=${tz}`);
    let newDesign = await designResponse.json();
    newDesign = parseNewDesign(newDesign)
    callback(newDesign)
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};



export const _getDesignArray = async (dobs, handleData = () => void (0)) => {
  if (!dobs) {
    handleData(null)
    return null
  }
  // dateTimeToId(date, time, null, setOutput)
  // calculateDesign(date, time, { city: city, title: title, timezone: timezone }, setOutput)
  // const url = `https://mikozaarray.pythonanywhere.com/array/${dobs}`;
  // // console.log('url = ', url);
  // fetch(url)
  //   .then((response) => response.json())
  //   .then((dat) => {
  //     handleData(dat)
  //   })
  //   .catch(error => {
  //     console.error("Error fetching: ", error);
  //   });




}


export const _createAvatar = async (
  avatarData = {},
  callback = () => void (0)
) => {
  const url = `${API_URL}/api/avatar/create/`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "name": '',
        ...avatarData
      }),
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    const data = await response.json();
    console.log('Success:', data);
    callback && callback(data)
  } catch (error) {
    console.error('Error:', error.message);
  }
};



export const _notificationsMarkRead = async (_id, callback) => {
  const url = `${API_URL}/api/notifications/read`;
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "_id": _id }),
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    const data = await response.json();
    console.log('Success:', data);
    callback && callback(data)
  } catch (error) {
    console.error('Error:', error.message);
  }
};

export const _notificationsMarkAllRead = async () => {
  const url = `${API_URL}/api/notifications/all-read`;
  try {
    const response = await fetch(url, {
      method: 'POST',
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
  } catch (error) {
    console.error('Error:', error.message);
  }
};



export const _invitedBy = async (token = '', callback = () => void (0)) => {
  const url = `${API_URL}/api/user/invited-by`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: token })
    });

    if (response.ok) {
      const data = await response.json();
      console.log('Success:', data);
      callback && callback(data);
    } else {
      console.log('Error: ', response.status);
    }
  } catch (error) {
    console.error('Error:', error.message);
  }

};




export const _createPenta = async (
  {
    // name = 'Unnamed penta',
    avatars = [] }, callback) => {
  const url = `${API_URL}/api/penta/create/`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Include any other headers you need, such as authorization
      },
      body: JSON.stringify({
        name: '',
        avatars: avatars
      })
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    console.log('Success:', data);
    callback && callback(data)
    // Handle success, e.g., showing a success message
  } catch (error) {
    console.error('Error:', error.message);
    // Handle error, e.g., showing an error message
  }
};




export const _setIsMe = async (token, callback = () => void (0)) => {
  const url = `${API_URL}/api/avatar/is-me/`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Include any other headers you need, such as authorization
      },
      // If you need to send a body with the POST request, include it here
      body: JSON.stringify({ token: token }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    console.log('Success:', data);
    callback && callback(data)
    // Handle success, e.g., showing a success message
  } catch (error) {
    console.error('Error:', error.message);
    // Handle error, e.g., showing an error message
  }
};

export const _unfollow = async (token, callback = () => void (0)) => {
  const url = `${API_URL}/api/user/unfollow`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Include any other headers you need, such as authorization
      },
      // If you need to send a body with the POST request, include it here
      body: JSON.stringify({ token: token }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    console.log('Success:', data);
    callback && callback(data)
    // Handle success, e.g., showing a success message
  } catch (error) {
    console.error('Error:', error.message);
    // Handle error, e.g., showing an error message
  }
};

export const _createImage = async (type = 'email', avatarToken, base64, callback = () => void (0)) => {
  const url = `${API_URL}/api/avatar/upload-body-graph/${avatarToken}/${type}`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: base64,
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    const data = await response.json();
    console.log('Success:', data);
    callback && callback(data)
    // Handle success, e.g., showing a success message
  } catch (error) {
    console.error('Error:', error.message);
    // Handle error, e.g., showing an error message
  }
};

export const _follow = async (token, callback = () => void (0)) => {
  const url = `${API_URL}/api/user/follow`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Include any other headers you need, such as authorization
      },
      // If you need to send a body with the POST request, include it here
      body: JSON.stringify({ token: token }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    console.log('Success:', data);
    callback && callback(data)
    // Handle success, e.g., showing a success message
  } catch (error) {
    console.error('Error:', error.message);
    // Handle error, e.g., showing an error message
  }
};


export const _updateAvatar = async (newData = {}, callback = () => void (0)) => {
  console.log('newData222 = ', newData);
  const url = `${API_URL}/api/avatar/update`;

  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        // Include any other headers you need, such as authorization
      },
      // If you need to send a body with the POST request, include it here
      body: JSON.stringify(newData),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    console.log('Success:', data);
    callback && callback(data)

    // if (data.design && data.design.type) {
    //   const svgEmail = renderBase64(data.design, 'thumbnail');
    //   const svgOg = renderBase64(data.design, 'og');
    //   _createImage('thumbnail', avatarToken, svgEmail)
    //   _createImage('og', avatarToken, svgOg)
    // }

    // Handle success, e.g., showing a success message
  } catch (error) {
    console.error('Error:', error.message);
    // Handle error, e.g., showing an error message
  }

};


export const _updateMe = async (newData = {}, callback = () => void (0)) => {
  const url = `${API_URL}/api/me/update`;
  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        // Include any other headers you need, such as authorization
      },
      // If you need to send a body with the POST request, include it here
      body: JSON.stringify(newData),
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    const data = await response.json();
    // console.log('Success:', data);
    callback && callback(data)
  } catch (error) {
    console.error('Error:', error.message);
  }
};


export const _addToPenta = async (pentaId = '', newAvatarId = '', callback = () => void (0)) => {
  console.log('newAvatarId = ', newAvatarId);
  const url = `${API_URL}/api/penta/avatar/add`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Include any other headers you need, such as authorization
      },
      // If you need to send a body with the POST request, include it here
      body: JSON.stringify({
        penta: pentaId,
        avatars: [newAvatarId]
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    console.log(`Added to ${pentaId} avatar ${newAvatarId}`, data);
    callback && callback(data)
    // Handle success, e.g., showing a success message
  } catch (error) {
    console.error('Error:', error.message);
    // Handle error, e.g., showing an error message
  }
};

export const _deleteFromPenta = async (pentaId = '', newAvatarId = '', callback = () => void (0)) => {
  const url = `${API_URL}/api/penta/avatar/delete`;

  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        // Include any other headers you need, such as authorization
      },
      // If you need to send a body with the POST request, include it here
      body: JSON.stringify({
        penta: pentaId,
        avatar: newAvatarId
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    console.log(`Ddeleted from ${pentaId} avatar ${newAvatarId}`, data);
    callback && callback(data)
    // Handle success, e.g., showing a success message
  } catch (error) {
    console.error('Error:', error.message);
    // Handle error, e.g., showing an error message
  }
};



export const _deleteAvatar = async (token = '', callback = () => void (0)) => {
  const url = `${API_URL}/api/avatar/delete`;

  try {
    const response = await fetch(url, {
      method: 'DELETE',
      // headers: {
      //   'Content-Type': 'application/json',
      // },
      body: JSON.stringify({ "token": token }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    callback()
    // Handle success, e.g., showing a success message
  } catch (error) {
    console.error('Error:', error.message);
    // Handle error, e.g., showing an error message
  }
};


export const _deletePenta = async (token = '', callback = () => void (0)) => {
  const url = `${API_URL}/api/penta/delete`;

  try {
    const response = await fetch(url, {
      method: 'DELETE',
      // headers: {
      //   'Content-Type': 'application/json',
      // },
      body: JSON.stringify({ "token": token }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    callback && callback()
    // Handle success, e.g., showing a success message
  } catch (error) {
    console.error('Error:', error.message);
    // Handle error, e.g., showing an error message
  }
};




// export const _getCityPeople = async (cityId, handleData = () => void (0)) => {
//   // dateTimeToId(date, time, null, setOutput)
//   // calculateDesign(date, time, { city: city, title: title, timezone: timezone }, setOutput)

//   const url = `${API_URL}/api/geo/city/${cityId}`;
//   // const url = `https://hd.cafe/api/geo/city/${cityId}`;

//   // console.log('url = ', url);
//   fetch(url)
//     .then((response) => response.json())
//     .then((dat) => {
//       handleData(dat)
//     })
//     .catch(error => {
//       console.error("Error fetching: ", error);
//     });
// }




const checkValidProfile = (newDesign) => {
  let p = getProfile(newDesign)
  p = `${p[0]}${p[1]}`;
  return (profilesValid.includes(p)) ? true : false;
}



function setCharAt(str, index, chr) {
  if (index > str.length - 1) return str;
  return str.substring(0, index) + chr + str.substring(index + 1);
}

export const convertGMTtoDateTime = (gmt = "1990-01-01T00:00:00+0000", offset = 0, what) => {

  if (gmt.length === 19) gmt = `${gmt}+0000`;

  console.log('gmt111 = ', gmt);
  const gmtRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{4}$/; // Correct format: YYYY-MM-DDTHH:mm:ss+0000
  if (!gmtRegex.test(gmt)) { gmt = '1990-01-01T00:00:00+0000' }

  let result = {};
  if (!gmt) { result = { date: '', time: '', iso: '' } } else {

    console.log('gmt222 = ', gmt);

  const gmtWoOffset = gmt.slice(0, 16);
  let gmtOffset = ":00+0000";
  if (offset) {
    offset = parseFloat(offset);

    //console.log("gmt_without_offset = ", gmtWoOffset);
    if (Math.abs(offset).toString().split('.').length > 1) {
      gmtOffset = setCharAt(gmtOffset, 5, Math.abs(offset).toString().split('.')[0]);
      gmtOffset = setCharAt(gmtOffset, 6, 6 / (10 / parseInt(Math.abs(offset).toString().split('.')[1], 10)));
    } else {
      gmtOffset = setCharAt(gmtOffset, 5, Math.abs(offset));
    }
    if (offset < 0) gmtOffset = setCharAt(gmtOffset, 3, '-');
  }
  const newGmt = gmtWoOffset + gmtOffset;
  const dateObject = new Date(newGmt);
  const iso = dateObject.toISOString();
  let date = dateObject.toISOString().split('T')[0];
  const timeFull = dateObject.toISOString().split('T')[1];
  const time = timeFull.substring(0, 5);
    result = { date, time, iso };

  }
  console.log('result = ', result);

  if (what) {
    if (what.includes(',')) {
      let a = what.split(',').map(one => `${result[one]}`).join(' ')
      console.log('a = ', a);
      return a;
    } else {
      return result[what];
    }
  }
  return result;
}


export const hideYear = (date = '1990-01-01') => {
  return `XXXX-${date.split('-')[1]}-${date.split('-')[2]}`
}


export const localizeDate = (str, locale, hideYear) => {

  const formats = hideYear ? {
    en: 'MMMM D',
    ru: 'D MMMM'
  } : {
    en: 'MMMM D, YYYY',
    ru: 'D MMMM YYYY'
  }
  const getLocale = () => {
    return formats.locale ? formats.locale : formats.en
  }
  dayjs.locale(locale);
  let ret = dayjs(str).format(getLocale());
  // if (hideYear) ret = ret.replace(str.split('-')[0], 'XXXX')
  // if (hideYear) ret = ret += ', 🔒'
  if (hideYear) ret = ret += ', ●●●●'
  // if (hideYear) ret = ret += ''
  return ret
}




export const convertDateTimeToGMT = (date = '1990-01-01', time = '12:00', offset = 0) => {
  // Split the date into year, month, and day components
  const [year, month, day] = date.split('-').map((component, index) => {
    // Pad the month and day with leading zeros if necessary
    return index > 0 ? component.padStart(2, '0') : component;
  });

  // Reassemble the date with proper padding
  const paddedDate = [year, month, day].join('-');

  // Combine the date and time into a single string
  const dateTimeString = `${paddedDate}T${time}:00`;

  // Calculate the timezone offset string
  const sign = offset >= 0 ? '+' : '-';
  const absOffset = Math.abs(offset);
  const hours = Math.floor(absOffset);
  const minutes = Math.round((absOffset - hours) * 60);

  // Pad hours and minutes to ensure two digits
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  const timezoneString = ``;
  // const timezoneString = `${sign}${formattedHours}${formattedMinutes}`;

  // Combine the dateTimeString with the timezoneString
  const result = `${dateTimeString}${timezoneString}`;
  return result;
};




export const convertOffsetToHHMM = (offset) => {
  // Determine the sign
  const sign = offset >= 0 ? '+' : '-';

  // Separate the hours and minutes
  const absoluteOffset = Math.abs(offset);
  const hours = Math.floor(absoluteOffset);
  const minutes = Math.round((absoluteOffset - hours) * 60); // Round to handle any floating-point arithmetic issues

  // Format hours and minutes as two digits
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  // Combine sign, hours, and minutes
  return `${sign}${formattedHours}${formattedMinutes}`;
}


export function getLatestUpdateDate(records) {
  // Initialize a variable to hold the latest update date
  let latestUpdateDate = null;

  // Iterate over each record in the object
  Object.values(records).forEach(record => {
    // Extract the update date from the record
    const updateDate = new Date(record.updated);

    // Compare the update date with the current latest update date
    if (!latestUpdateDate || updateDate > latestUpdateDate) {
      latestUpdateDate = updateDate;
    }
  });

  // Return the latest update date as a formatted string (optional)
  return latestUpdateDate ? latestUpdateDate.toISOString() : null;
}




export const adjustUTCByOffset = (utcString, offsetString) => {
  const offset = parseFloat(offsetString);
  const time = dayjs(utcString);
  const adjustedTime = time.subtract(offset * 60, 'minute');
  console.log('adjustedTime.format() = ', adjustedTime.format());
  let ret = `${adjustedTime.format().slice(0, -6)}+0000`;
  return ret;
};



export const getCenterByGate = (gate) => {
  return gates_dictionary[gate]['center']
}



export const getCrossType = (profile) => {
  if (!profile || !profile.length) return ''
  let t = crosses_types.filter(one => one['profiles'].filter(oneT => oneT[0] === profile[0] && oneT[1] === profile[1]).length)[0]
  return t ? t['id'] : ''
}


export const getCross = (cross, profile) => {
  let cr = crosses_dictionary.filter(one => one['gates'][0] === cross[0] && one['gates'][1] === cross[1] && one['gates'][2] === cross[2] && one['gates'][3] === cross[3])
  if (!cr.length || !profile) return {};
  if (cr.length === 1) return cr[0];
  if (profile[0] === 4 && profile[1] === 1) {
    if (cr[0]['title'].includes('The Juxtaposition')) return cr[0];
  } else {
    if (cr[0]['title'].includes('The Juxtaposition')) return cr[1];
  }
}








const getPentaChannels = () => {

}






function motorToTT(channels, definedCenters) {
  if (!definedCenters.some(e => /TT/.test(e)) || !definedCenters.some(e => /SP|SL|RT|HT/.test(e))) {
    return false; // TT undefined and/or no motor defined
  }

  // SP
  if (definedCenters.some(e => /SP/.test(e))) {
    if (channels.some(e => /12-22|35-36/.test(e))) {
      return true;
    }
  }

  // SL
  if (definedCenters.some(e => /SL/.test(e))) {

    if (channels.some(e => /20-34/.test(e))) {
      return true;
    }

    if (channels.some(e => /2-14|5-15|29-46/.test(e))) { // G Center is defined
      if (channels.some(e => /1-8|7-31|10-20|13-33/.test(e))) {
        return true;
      }
    }

    if (channels.some(e => /27-50/.test(e))) { // Spleen is defined
      if (channels.some(e => /16-48|20-57/.test(e))) {
        return true;
      }
      if (channels.some(e => /10-57/.test(e))) { // G Center is defined
        if (channels.some(e => /1-8|7-31|10-20|13-33/.test(e))) {
          return true;
        }
      }
    }

  }

  // HT
  if (definedCenters.some(e => /HT/.test(e))) {
    if (channels.some(e => /21-45/.test(e))) {
      return true;
    }
    if (channels.some(e => /25-51/.test(e))) { // G Center is defined
      if (channels.some(e => /1-8|7-31|10-20|13-33/.test(e))) {
        return true;
      }
      if (channels.some(e => /10-57/.test(e))) { // Spleen is defined
        if (channels.some(e => /16-48|20-57/.test(e))) {
          return true;
        }
      }
    }
    if (channels.some(e => /26-44/.test(e))) { // Spleen is defined by HT
      if (channels.some(e => /16-48|20-57/.test(e))) {
        return true;
      }
      if (channels.some(e => /10-57/.test(e))) { //HT to Spleen to G
        if (channels.some(e => /7-31|1-8|13-33/.test(e))) { // G to Throat
          return true;
        }
      }
    }


  }

  // RT
  if (definedCenters.some(e => /RT/.test(e))) {
    if (channels.some(e => /18-58|28-38|32-54/.test(e))) { // Spleen is defined
      if (channels.some(e => /16-48|20-57/.test(e))) {
        return true;
      }
    }
    if (channels.some(e => /10-57/.test(e))) { // G Center is defined
      if (channels.some(e => /1-8|7-31|10-20|13-33/.test(e))) {
        return true;
      }
    }
  }
  return false;
}

// export default function MotorToTTComponent() {
//   const channels = ['12-22', '35-36'];
//   const definedCenters = ['TT', 'SP'];

//   const result = motorToTT(channels, definedCenters);

//   return (
//       <div>
//           <p>Result: {result ? 'True' : 'False'}</p>
//       </div>
//   );
// }





export const sortBy = (arr, sortBy = 'profiles') => {
  if (!arr || !arr.length) return []

  let arrNew = JSON.parse(JSON.stringify(arr));

  if (sortBy === 'leave') return arr

  let noBodys = arrNew.filter(one => one.avatar.design.id === 'dummy' || !one.avatar.design.id)
  arrNew = arrNew.filter(one => one.avatar.design.id && one.avatar.design.id !== 'dummy')

  // const myDesign = context.handles.getUser(context.state.currentUser, 'design')
  // let arrNew = arr.map(oneUser => ({
  //   ...oneUser,
  //   'sameChannels': getSameChannels(myDesign, oneUser['design']).length,
  //   'sameGates': getSameGates(myDesign, oneUser['design']).length,
  //   'emg': getEmg(myDesign, oneUser['design']).length,
  // }))

  // if (sortBy === 'sameChannels') {
  //   return arrNew.sort((a, b) => {
  //     return b['sameChannels'] - a['sameChannels']
  //   })
  // }

  // if (sortBy === 'sameGates') {
  //   return arrNew.sort((a, b) => {
  //     return b['sameGates'] - a['sameGates']
  //   })
  // }

  // if (sortBy === 'emg') {
  //   return arrNew.sort((a, b) => {
  //     return b['emg'] - a['emg']
  //   })
  // }


  const typeOrder = {
    "G": 1,
    "MG": 2,
    "P": 3,
    "M": 4,
    "R": 5,
    "No": 6
  };

  const sortByProfiles = (arr) => {
    return arr.sort((a, b) => {
      const profileA = b.avatar.design ? a.avatar.design.profile : [9, 9];
      const profileB = b.avatar.design ? b.avatar.design.profile : [9, 9];
      if (!profileA || !profileB) {
        return false
      }
      if (profileA[0] === profileB[0]) {
        return profileA[1] - profileB[1];
      } else {
        return profileA[0] - profileB[0];
      }
    })
  }


  const sortByTypes = (arr) => {
    return arr.sort((a, b) => {
      const typeA = b.avatar.design ? a.avatar.design.type : "No";
      const typeB = b.avatar.design ? b.avatar.design.type : "No";
      return typeOrder[typeA] - typeOrder[typeB]
    })
  }

  if (sortBy === 'types') {
    let arrT = []
    Object.keys(typeOrder).forEach(oneType => {
      let arrType = arrNew.filter(one => one.avatar.design.type === oneType)
      arrType = sortByProfiles(arrType);
      arrT.push(arrType)
    })
    arrNew = [...arrT.flat()]

  }


  if (sortBy === 'profiles') {
    arrNew = sortByProfiles(arrNew)
  }


  return [...arrNew, ...noBodys];
}





const getVariables = (newDesign) => {
  const prsSunTone = newDesign.personality.length ? newDesign.personality.filter(one => one.planet === 'Sun')[0].tone : null
  const prsNodeTone = newDesign.personality.length ? newDesign.personality.filter(one => one.planet === 'North_Node')[0].tone : null
  const desSunTone = newDesign.design.length ? newDesign.design.filter(one => one.planet === 'Sun')[0].tone : null
  const desNodeTone = newDesign.design.length ? newDesign.design.filter(one => one.planet === 'South_Node')[0].tone : null

  // variables are calculated based on tones of sun(birth,design) and
  // Nodes(birth,design), respectively
  // If tone 1,2,3-> left arrow, else right arrow
  // (df["tone"][0]),  # sun at birth
  // (df["tone"][3]),  # Node at birth
  // (df["tone"][idx]),  # sun at design
  // (df["tone"][idx + 3]),  # node at design
  // const vars = ["right_up", "right_down", "left_up", "left_down"]

  // bodygraph.variable += bodygraph.activations.Personality.Sun.Tone < 4 ? "L" : "R";
  // bodygraph.variable += bodygraph.activations.Personality.NorthNode.Tone < 4 ? "L" : "R";
  // bodygraph.variable += bodygraph.activations.Design.Sun.Tone < 4 ? "L" : "R";
  // bodygraph.variable += bodygraph.activations.Design.SouthNode.Tone < 4 ? "L" : "R";

  const vars = {
    "left_up": desSunTone < 4 ? "left" : "right",
    "left_down": desNodeTone < 4 ? "left" : "right",
    "right_up": prsSunTone < 4 ? "left" : "right",
    "right_down": prsNodeTone < 4 ? "left" : "right",
  }
  return vars;

}

export const getType = (gates) => {
  let active_chakras = getDesignCenters(gates)
  let type = 'R';
  const channelsIds = gatherUserChannels(gates).map(one => one.id)
  if (channelsIds.length && !active_chakras.includes('SL')) type = 'P'
  if (active_chakras.includes('SL')) type = 'G'
  if (active_chakras.includes('SL') && motorToTT(channelsIds, active_chakras)) {
    type = 'MG'
  }
  if (!active_chakras.includes('SL') && motorToTT(channelsIds, active_chakras)) {
    type = 'M'
  }
  return type
}

const getGateByPlanet = (gates, planet, what) => {
  if (!gates.length) return null;
  let g = gates.filter(one => one.planet === planet)[0];
  let r = g;
  if (what) {
    r = g[what]
  }
  return r;
}


const getProfile = (newDesign) => {
  const prsSunLine = getGateByPlanet(newDesign.personality, 'Sun', 'line')
  const desSunLine = getGateByPlanet(newDesign.design, 'Sun', 'line')
  return [prsSunLine, desSunLine]
}

const getDesignCross = (newDesign) => {
  const prsSunLine = getGateByPlanet(newDesign.personality, 'Sun', 'gate')
  const prsEarthLine = getGateByPlanet(newDesign.personality, 'Earth', 'gate')
  const desSunLine = getGateByPlanet(newDesign.design, 'Sun', 'gate')
  const desEarthLine = getGateByPlanet(newDesign.design, 'Earth', 'gate')
  return [prsSunLine, prsEarthLine, desSunLine, desEarthLine]
}

export const parseDesignInPersonas = (personas) => {
  if (!personas) return []
  personas = personas.map(one => ({
    ...one,
    avatar: {
      ...one.avatar,
      //TODO: dob убрать замену Т
      dob: one.avatar.dob ? one.avatar.dob.replace(' ', 'T') : '',
      design: one.avatar.design['meta'] ? parseNewDesign(one.avatar.design) : one.avatar.design
    }
  }))
  return personas
}



const getDesignAuthority = (active_chakras) => {
  let auth = '';

  if (active_chakras.length === 0) {
    auth = "Lunar";
  } else {
    const authorityHierarchy = ["SP", "SL", "SN", "HT", "GC"];
    const authorityMap = {
      "SP": "SP",
      "SL": "SL",
      "SN": "SN",
      "HT": "HT",
      "GC": "GC"
    };

    for (let i = 0; i < authorityHierarchy.length; i++) {
      if (active_chakras.includes(authorityHierarchy[i])) {
        auth = authorityMap[authorityHierarchy[i]];
        break;
      }
    }

    if (auth === '') {
      auth = "sounding_board";
    }
  }

  return auth;
};

export const parseAvatar = (avatar) => {

  if (avatar.design.meta || avatar.design.dd) {
    avatar.design = parseNewDesign(avatar.design)
  }

  return avatar
}



export const parseNewDesign = (newDesign, prsdes) => {

  // console.log('newDesign = ', newDesign);
  if (newDesign.dd) newDesign = parseNewDesign2(newDesign);

  if (!newDesign || !newDesign.meta) return default_design;
  if (!newDesign.meta.personality) return default_design;
  if (!newDesign.personality.length && !newDesign.design.length) return default_design
  if (newDesign.personality.length && newDesign.design.length && !checkValidProfile(newDesign)) {
    return default_design;
  }

  const gates = []
  newDesign.personality.forEach(one => {
    gates.push({ ...one, label: 'prs' })
  })
  newDesign.design.forEach(one => {
    gates.push({ ...one, label: 'des' })
  })

  const channels = JSON.parse(JSON.stringify(gatherUserChannels(gates)));

  channels.forEach(oneChannel => {
    oneChannel.activatedWith = {};

    const g0 = findGateInUser(gates, oneChannel.gates[0])
    const g1 = findGateInUser(gates, oneChannel.gates[1])

    if (g0.length && g1.length) {

      if (g0.length) {
        oneChannel.activatedWith[oneChannel.gates[0]] = [
          g0,
      // findGateInUser(gates, oneChannel.gates[1])
        ];
      }
      if (g1.length) {
        oneChannel.activatedWith[oneChannel.gates[1]] = [
          // findGateInUser(gates, oneChannel.gates[0]),
          g1
        ];
      }
    }
  })

  newDesign.id = newDesign.meta.personality;
  newDesign.gates = gates;
  newDesign.channels = channels;
  newDesign.type = getType(gates);
  newDesign.profile = getProfile(newDesign);
  newDesign.variables = getVariables(newDesign);
  newDesign.active_chakras = getDesignCenters(gates);
  newDesign.auth = getDesignAuthority(getDesignCenters(gates));
  newDesign.inc_cross = getDesignCross(newDesign);
  newDesign.inc_cross_typ = '';
  newDesign.split = '';
  if (!prsdes) {
    newDesign.prs = getPrsDes(newDesign, 'types').prs;
    newDesign.des = getPrsDes(newDesign, 'types').des;
  }
  // console.log('newDesign = ', newDesign);
  return newDesign

}


export const getPrsDes = (design, what = 'types') => {

  let newDesignPrs = JSON.parse(JSON.stringify(design))
  newDesignPrs.design = [];
  newDesignPrs = parseNewDesign(newDesignPrs, true);
  // newDesignPrs.design = design.design;
  const prs = newDesignPrs;

  let newDesignDes = JSON.parse(JSON.stringify(design))
  newDesignDes.personality = [];
  newDesignDes = parseNewDesign(newDesignDes, true);
  // newDesignDes.personality = design.personality;
  const des = newDesignDes;

  if (what === 'types') {
    return {
      'prs': prs.type,
      'des': des.type,
    }
  }

  if (what === 'prs') {
    return prs
  }
  if (what === 'des') {
    return des
  }


}


export const validateHistory = (h) => {
  return h.filter(one => validateDesign(one.avatar.design))

}


export const validateDesign = (d) => {
  if (
    d.meta &&
    d.design &&
    d.personality
  ) {
    return true
  } else {
    return false
  }
}


const filterCenters = (design = {}, center = '') => {

  const userChannels = gatherUserChannels(design['gates'], 'return channels')

  if (center) {
    return userChannels.filter(oneChannel => oneChannel['centers'].includes(center))
  } else {
    return userChannels
  }
}


const getActivations = (gates = [], gatesArr = []) => {
  const activations = {}
  gatesArr.forEach(oneGateId => {
    const g = findGateInUser(gates, oneGateId)
    if (g.length) {
      activations[oneGateId] = g
    }
  })
  // console.log('activations = ', activations);
  return activations;
}


export const prepareChannels = (gates = [], returnGates) => {

  let channelsArr = gatherUserChannels(gates)
// let channelsArr = filterCenters(filterByCenter)



  channelsArr = channelsArr.map(oneChannel => {
    oneChannel = {
      ...oneChannel,
      'activatedWith': {
        ...getActivations(gates, oneChannel.gates)
      }
    }
    return oneChannel;
  })

  channelsArr =
    returnGates ?
    channelsArr.filter(oneChannel => Object.keys(oneChannel.activatedWith).length === 1)
      :
    channelsArr.filter(oneChannel => Object.keys(oneChannel.activatedWith).length === 2)

  return channelsArr
}

export const prepareGates = (gates = [], label) => {
  if (!gates.length) return []
  let uniqueGates = []
  if (label) {
    gates = gates.filter(oneGate => oneGate.label === label)
  }
  uniqueGates = [...new Set(gates.map(oneGate => oneGate.gate))]
  const allGates = {}
  uniqueGates.forEach(oneGateId => {
    allGates[oneGateId] = findGateInUser(gates, oneGateId)
  })
  return allGates;
}




export const handleCenterClick = (centerId, location = {}, navigate = () => void (0)) => {
  let newPath = location.pathname.split('/study')[0];
  newPath = newPath.slice(-1) === '/' ? newPath + `study/center:${centerId}` : newPath + `/study/center:${centerId}`;
  const queryParams = location.search ? location.search : '';
  navigate(`${newPath}${queryParams}`);
};
export const handleChannelClick = (channelId, location, navigate) => {
  let newPath = location.pathname.split('/study')[0];
  newPath = newPath.slice(-1) === '/' ? newPath + `study/channel:${channelId}` : newPath + `/study/channel:${channelId}`
  const queryParams = location.search ? location.search : '';
  navigate(`${newPath}${queryParams}`);
};
export const handleGateClick = (gateId, location, navigate) => {
  let newPath = location.pathname.split('/study')[0];
  newPath = newPath.slice(-1) === '/' ? newPath + `study/gate:${gateId}` : newPath + `/study/gate:${gateId}`;
  const queryParams = location.search ? location.search : '';
  navigate(`${newPath}${queryParams}`);
};
